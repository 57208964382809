import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Loading from '../components/Loading';
import Meta from '../components/Meta';

const NotFoundPage = () => {
    const [content, setContent] = useState(<Loading color="black.main" />);
    setTimeout(() => {
        setContent('Sorry, page not found.');
    }, 5000);

    return (
        <Grid
            alignItems="center"
            container={true}
            justifyContent="center"
            sx={{ display: 'flex' }}
            height="100vh"
            width="100%"
        >
            <Grid item>{content}</Grid>
        </Grid>
    );
};

export default NotFoundPage;

export const Head = ({ location }) => {
    return <Meta location={location} />;
};
